import React, { CSSProperties } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import { TextareaAutosize } from "@material-ui/core";
import {createTopics, getLoginUser} from "../../utils/user.utils";
import Button from "../../components/CustomButtons/Button";
import TextField from '@material-ui/core/TextField';
import {askIPIPA, getTopics} from "../../utils/user.utils";
import GridItem  from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import ReactHtmlParser from "html-react-parser";
import ChatIcon from '@material-ui/icons/Chat';

const myStyles: CSSProperties = {
    position: 'relative',
    flexDirection: 'row',
}

const styles = {
    chatboxColor: {
        // color: '#3C4858',
        color: '#FFFFFF',
        fontFamily: 'Roboto, Arial, Helvetica',
    },
    multilineColor: {
        // color: '#3C4858',
        color: '#FFFFFF',
        fontFamily: 'Roboto, Arial, Helvetica',
    },
    title: {
        fontSize: '18px',
        fontWeight: 400,
        padding: '3px 0px',
        color: '#5A5A5A',
        marginLeft: '0px',
    },
    bigBox: {
        backgroundColor: '#465366',
        marginTop: '40px',
        borderRadius: '15px',
        fontFamily: 'Roboto, Arial, Helvetica',
        border: '1px solid #465366',
        zIndex: 9,
        boxShadow: '10px 10px 5px #ccc',
    },
    questionBox: {
        color: 'white',
        minHeight: '50px',
        backgroundColor: '#465366',
        // backgroundColor: '#000000',
        padding: '5px 10px',
        borderRadius: '10px',
        fontSize: '16px',
        fontFamily: 'Roboto, Arial, Helvetica',
        zIndex: 1,
    },
    chatBoxTitle: {
        width: '110px',
        padding: '3px',
        backgroundColor: '#465366',
        color: 'white',
        fontWeight: 600,
        marginLeft: '8px',
        zIndex: 9,
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    chatBox: {
        color: 'white',
        minHeight: '500px',
        backgroundColor: '#465366',
        padding: '10px 25px',
        borderRadius: '10px',
        fontSize: '16px',
        fontFamily: 'Roboto, Arial, Helvetica',
        zIndex: 1,
    },
    outline: {
        color: 'white',
        // minHeight: '100px',
        fontWeight: 600,
        backgroundColor: '#465366',
        padding: '8px 15px 5px 15px',
        marginTop: '10x',
        borderRadius: '10px',
        fontSize: '16px',
        fontFamily: 'Roboto, Arial, Helvetica',
        border: '1px solid #465366',
    },
    frame: {
        color: 'white',
        fontWeight: 600,
        backgroundColor: '#465366',
        padding: '8px 15px 15px 15px',
        marginTop: '10px',
        borderRadius: '10px',
        fontSize: '16px',
        fontFamily: 'Roboto, Arial, Helvetica',
        border: '1px solid #465366',
        boxShadow: '10px 10px 5px #ccc',
    },
    askBox: {
        // marginTop: '50px',
        color: 'white',
        height: '100px',
        backgroundColor: '#465366',
        // backgroundColor: '#000000',
        padding: '15px',
        borderRadius: '8px',
        fontSize: '16px',
        fontFamily: 'Roboto, Arial, Helvetica',
        border: '1px solid #808080',
    },
    submitBox: {
        height: '35px',
        marginLeft: '10px',
        marginTop: '-200px',
        fontSize: '12px',
    },
    frame2: {
        color: 'black',
        fontWeight: 500,
        padding: '8px 15px 15px 15px',
        margin: '10px 120px',
        borderRadius: '10px',
        fontSize: '18px',
        fontFamily: 'Roboto, Arial, Helvetica',
        border: '1px solid #465366',
    },
};

const useStyles = makeStyles(styles);

export default function AskIPIPAView() {
    const [welcomeMessage, setWelcomeMessage] = React.useState('');
    const [topicId, setTopicId] = React.useState('');
    const [message, setMessage] = React.useState('Welcome to Ask iPIPA!');
    const [chatContent, setChatContent] = React.useState('');
    const [developerState, setDeveloperState] = React.useState(false);
    const [askInput, setAskInput] = React.useState('');
    const [question, setQuestion] = React.useState('');

    const welcome_message = 'Launching Ask iPIPA...............';
    const alternative_message = 'This feature will be available soon';
    const temp_message = 'Please wait................';
    const index = React.useRef(0);
    const index2 = React.useRef(0);

    const updateMessage = ((message: string) => {
        index.current = 0;
        setMessage(message);
        setChatContent('');
    });

    const getUserTopics = () => {
        getTopics()
            .then((response) => {
                const data = response && response?.data ? response.data : null;
                if (data.code === 200) {
                    var topics = data.data;
                    if (topics[0] != null) {
                        // console.info('topic id: %o', topics[0].id);
                        setTopicId(topics[0].id);
                    } else {
                        console.info("no existing topic");
                        createTopics();
                    }
                }
            })
            .catch((error) => {
                console.debug(error);
            });
    }

    const askQuestion = () => {
        let question = askInput;
        setQuestion(question);
        setAskInput('');
        updateMessage(temp_message);
        askIPIPA(topicId, question)
            .then((response) => {
                const data = response && response?.data ? response.data : null;
                if (data.code === 200) {
                    const answer = data.data;
                    updateMessage(answer);
                }
            })
            .catch((error) => {
                // "errMsg": "java.net.SocketTimeoutException: Read timed out"
                // A JSONObject text must begin with '{' at 1 [character 2 line 1]
                const data = error && error?.data ? error.data : null;
                if (data != null) {
                    console.debug('Error:' + data.errMsg);
                }
                updateMessage('Sorry. I encountered an error. Please try again.');
            });
    }

    const handleSubmit = () => {
        askQuestion();
    }

    const classes = useStyles();

    //developer official version
    // temporary version
    React.useEffect(() => {
        function tick() {
            setWelcomeMessage(prev => prev + welcome_message[index2.current]);
            index2.current++;
        }
        if (index2.current < welcome_message.length) {
            let addChar = setInterval(tick, 20);
            return () => clearInterval(addChar);
        } else {
            window.location.replace('https://chatgpt.com/g/g-qNswPAHWT-ask-ipipa-beta3')
        }
    }, [welcomeMessage]);

    // React.useEffect(() => {
        // getLoginUser(null)
        //     .then((response) => {
        //         const data = response && response?.data ? response.data : null;
        //         if (data.code === 200) {
        //             if (data.data.developerState === 'Enabled') {
        //                 setDeveloperState(true);
        //             }
        //         }
        //     })
        //     .catch((error) => {
        //     });
        // getUserTopics();
    // }, []);

    const handleChange = (e: any) => {
        e.preventDefault();
        setAskInput(e.target.value);
    };
    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            e.preventDefault(); // prevent Enter behaviour
            handleSubmit();
        }
    }

    const gridProps =  {
        xs: 12,
        sm: 12,
        md: 12,
        style : { margin: '0px 120px'}
    }

    // React.useEffect(() => {
    //     window.location.replace('https://chat.openai.com/g/g-fZw1N4ao9-ask-ipipa-dev-version')
    // }, [])

    return (
        <>
            {developerState? (
                <div style={{marginTop: '-57px'}}>
                    <hr  style={{width: '100%', borderStyle: 'inset'}}/>
                    <div>
                        <GridContainer>
                            <GridItem {...gridProps}>
                                <div className={classes.frame}>
                                    Ask Me Now
                                    <TextField className={classes.askBox}
                                               placeholder="Type your question here then press Enter"
                                               onChange={handleChange}
                                               onKeyPress={handleKeyPress}
                                               value={askInput}
                                               multiline
                                               variant="standard"
                                               fullWidth
                                               InputProps={{
                                                   disableUnderline: true,
                                                   className: classes.multilineColor,
                                               }}
                                               autoFocus
                                    />
                                </div>
                                <div className={classes.bigBox}>
                                <div id='questionBox' className={classes.outline}>
                                    Your Question:
                                    <TextField className={classes.questionBox}
                                       value={question}
                                       multiline
                                       variant="standard"
                                       fullWidth
                                       InputProps={{
                                           disableUnderline: true,
                                           readOnly: true,
                                           className: classes.multilineColor,
                                       }}
                                    />
                                </div>
                                <hr style={{borderColor: '#808080'}}/>
                                <div className={classes.chatBoxTitle} style={myStyles}><ChatIcon style={{padding:'3px'}}/>Ask iPIPA:</div>
                                <TextField id='chatbox' className={classes.chatBox}
                                    // placeholder="Please type here then press Enter"
                                   onChange={handleChange}
                                   onKeyPress={handleKeyPress}
                                   value={chatContent}
                                   multiline
                                   variant="standard"
                                   fullWidth
                                   InputProps={{
                                       disableUnderline: true,
                                       readOnly: true,
                                       className: classes.chatboxColor,
                                   }}
                                />
                                </div>
                                {/*</div>*/}
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            ) : (
                <div>
                    <div className={classes.frame2}>
                    <div style={{margin: "20px 100px"}}>{welcomeMessage}</div>
                    </div>
                </div>
            )}
        </>
    );
}
