import React from 'react';
import { Link } from 'react-router-dom';
// react plugin for creating charts
import ChartistGraph from 'react-chartist';
import ReactGA from 'react-ga4';
import moment from 'moment';
// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
// @material-ui/icons
import Store from '@material-ui/icons/Store';
import Warning from '@material-ui/icons/Warning';
import Info from '@material-ui/icons/Info';
import DateRange from '@material-ui/icons/DateRange';
import LocalOffer from '@material-ui/icons/LocalOffer';
import Update from '@material-ui/icons/Update';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import AccessTime from '@material-ui/icons/AccessTime';
import Accessibility from '@material-ui/icons/Accessibility';
import BugReport from '@material-ui/icons/BugReport';
import Code from '@material-ui/icons/Code';
import Cloud from '@material-ui/icons/Cloud';
// core components
import { stringUtils } from '../../utils/string.utils';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Tasks from 'components/Tasks/Tasks.js';
import CustomTabs from 'components/CustomTabs/CustomTabs.js';
import Danger from 'components/Typography/Danger.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import Newsletter from './Newsletter.view.tsx';
import InvestmentSummary from './InvestmentSummary.view.tsx';
import GetStarted from './GetStarted.view.tsx';
import NewFeature from './NewFeature.view.tsx';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import {
  getUserId,
  getTextColor,
  getUppercaseEachWord,
  moneyValue,
  moneyValueInUnit,
  getEstDateTime,
} from '../../utils/common';

import DataTable from 'react-data-table-component';
import SortIcon from '@material-ui/icons/ArrowDownward';
import { getDashboard, getNewsletter, getInvestmentSummary, getUserOption, setUserOption} from '../../utils/dashboard.utils';
import iPapaIBSetupPdf from '../../assets/help/Interactive Brokers Account Setup Guide.pdf';
import iPapaFQAPdf from 'assets/help/GoPIPA Frequently Asked Questions 03262024.pdf';
// import iPapaAQMServicePdf from 'assets/help/How to use iPIPA AQM services.pdf';
import iPapaOverviewPdf from 'assets/help/GoPIPA Company and Solution Overview 03262024.pdf';
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles(styles);
const strategyDetailsPathName = '/admin/strategyDetails';
const aqmDetailsPathName = '/admin/aqmStrategyDetails';
const Seminar20250328Video = 'https://gopipa.s3.us-west-2.amazonaws.com/GoPIPA+User+Seminar+03282025.mp4';

//flag to show benchmark spy or not
const useBenchmark= true

const columns1 = [
  {
    name: 'ID',
    selector: 'strategyId',
    sortable: false,
    width: '60px',
  },
  {
    name: 'Name',
    selector: 'name',
    sortable: false,
    width: '180px',
    cell: (row) => {
      let path = row.isAQM?aqmDetailsPathName:strategyDetailsPathName;
      return (
        <div>
          <Link
            className={getTextColor(row.name)}
            to={{
              pathname: (path),
              state: {
                strategyId: row.strategyId,
                strategyName: row.name,
              },
            }}
          >{`${row.name}`}</Link>
        </div>
      );
    },
  },
  {
    name: 'Risk Level',
    selector: 'riskLevel',
    sortable: false,
    cell: (row) => {
      return <div style={{ width: 90 }}>{row.riskLevel.split(':')[1]}</div>;
    },
    // grow: 2,
    // format: (row) => row.riskLevel.split(':')[1],
  },
  {
    name: 'Total Return',
    selector: (row) => row.totalReturn,
    right: true,
    sortable: false,
    cell: (row) => {
      return <div style={{ width: 70, textAlign: 'right' }}>{row.totalReturn}</div>;
    },
  },
  {
    name: 'Subscription',
    selector: (row) => row.numberOfSubscription,
    right: true,
    sortable: false,
    omit: true,
  },
  {
    name: 'Value($)',
    selector: (row) => row.portfolioValue,
    right: true,
    sortable: false,
    format: (row) => moneyValue(row.portfolioValue),
    grow: 2,
    omit: true,
  },
  {
    name: 'Volatility',
    selector: (row) => row.volatility,
    right: true,
    sortable: false,
  },
  {
    name: 'Max Drawdown',
    selector: (row) => row.maxDrawdown,
    right: true,
    sortable: false,
    width: '120px',
  },
];

const columns2 = [
  {
    name: 'ID',
    selector: 'strategyId',
    sortable: false,
    width: '60px',
  },
  {
    name: 'Name',
    selector: 'name',
    sortable: false,
    width: '180px',
    cell: (row) => {
      let path = row.isAQM?aqmDetailsPathName:strategyDetailsPathName;
      return (
        <div>
          { (row.strategyId != '')? (
          <Link
            className={getTextColor(row.name)}
            to={{
              pathname: (path),
              state: {
                strategyId: row.strategyId,
                strategyName: row.name,
              },
            }}
          >{`${row.name}`}</Link>
            ) : `${row.name}`}
        </div>
      );
    },
  },
  {
    name: 'Risk Level',
    selector: 'riskLevel',
    sortable: false,
    cell: (row) => {
      return <div style={{ width: 90 }}>{row.riskLevel.split(':')[1]}</div>;
    },
  },
  {
    name: 'Total Return',
    selector: (row) => row.totalReturn,
    right: true,
    sortable: false,
    cell: (row) => {
      return <div style={{ width: 70, textAlign: 'right' }}>{row.totalReturn}</div>;
    },
  },
  {
    name: 'Volatility',
    selector: (row) => row.volatility,
    right: true,
    sortable: false,
  },
  {
    name: 'Max Drawdown',
    selector: (row) => row.maxDrawdown,
    right: true,
    sortable: false,
    width: '120px',
  },
];

const columns3 = [
  {
    name: 'ID',
    selector: 'strategyId',
    sortable: false,
    width: '60px',
  },
  {
    name: 'Name',
    selector: 'name',
    sortable: false,
    width: '180px',
    cell: (row) => {
      let path = row.isAQM?aqmDetailsPathName:strategyDetailsPathName;
      return (
        <div>
          { (row.strategyId != '')? (
              <Link
                  className={getTextColor(row.name)}
                  to={{
                    pathname: path,
                    state: {
                      strategyId: row.strategyId,
                      strategyName: row.name,
                    },
                  }}
              >{`${row.name}`}</Link>
          ): `${row.name}`}
        </div>
      );
    },
  },
  {
    name: 'Risk Level',
    selector: 'riskLevel',
    sortable: false,
    cell: (row) => {
      return <div style={{ width: 90 }}>{row.riskLevel.split(':')[1]}</div>;
    },
  },
  {
    name: 'Volatility',
    selector: (row) => row.volatility,
    right: true,
    sortable: false,
  },
  {
    name: 'Total Return',
    selector: (row) => row.totalReturn,
    right: true,
    sortable: false,
    cell: (row) => {
      return <div style={{ width: 70, textAlign: 'right' }}>{row.totalReturn}</div>;
    },
  },
  {
    name: 'Subscription',
    selector: (row) => row.numberOfSubscription,
    right: true,
    sortable: false,
    omit: true,
  },
  {
    name: 'Max Drawdown',
    selector: (row) => row.maxDrawdown,
    right: true,
    sortable: false,
    width: '120px',
  },
];

const columns4 = [
  {
    name: 'ID',
    selector: 'strategyId',
    sortable: false,
    width: '60px',
  },
  {
    name: 'Name',
    selector: 'name',
    sortable: false,
    width: '45%',
    cell: (row) => {
      let path = strategyDetailsPathName;
      return (
          <div>
            {
              <Link
                  className={getTextColor(row.name)}
                  to={{
                    pathname: path,
                    state: {
                      strategyId: row.strategyId,
                      strategyName: row.name,
                    },
                  }}
              >{`${row.name}`}</Link>
            }
          </div>
      );
    },
  },
  {
    name: 'Risk Level',
    selector: 'riskLevel',
    sortable: false,
    width: '25%',
  },
  {
    name: 'Release Date',
    selector: 'onlineTime',
    sortable: false,
    width: '20%',
    cell: (row) => {
      let date = moment( row.onlineTime.replace(/\.\d+/, "").replace(/-/g,"/")).tz('America/New_York').format('YYYY-MM-DD');
      return (
          <div>
            {date}
          </div>
      );
    },
  },
]

export default function DashboardView() {
  const userid = getUserId();
  ReactGA.initialize('G-6HFVDBYYL1', {
    gaOptions: {
      userId: userid
    }
  });
  ReactGA.send({ hitType: "pageview", page: "/dashboard" });
  const classes = useStyles();
  const [dashboard, setDashboard] = React.useState(null);
  const [summary, setSummary] = React.useState(null);
  const [latest, setLatest] = React.useState(null);
  const [newsletter, setNewsletter] = React.useState(null);
  const [popupState, setPopupState] = React.useState({ open: false });
  const [newletterDate, setNewsletterDate] = React.useState(null);
  const [newsletterSubtitle1, setNewsletterSubtitle1] = React.useState("");
  const [newsletterMarketview, setNewsletterMarketview] = React.useState(null);
  const [newsletterSubtitle2, setNewsletterSubtitle2] = React.useState("");
  const [newsletterRating, setNewsletterRating] = React.useState("");
  const [newsletterSubtitle3, setNewsletterSubtitle3] = React.useState("");
  const [newsletterNote, setNewsletterNote] = React.useState("");
  const [summaryState, setSummaryState] = React.useState({ open: false });
  const [aqsSummary, setAqsSummary] = React.useState({ open: false });
  const [aqmSummary, setAqmSummary] = React.useState({ open: false });
  const [getStartedState, setGetStartedState] = React.useState({ open: false });
  const [newfeatureState, setNewfeatureState] = React.useState({ open: false });

  React.useEffect(() => {
    getDashboard()
      .then((response) => {
        if (response?.data.code === 200) {
          let summary = response?.data?.data.summary;
          summary.lastUpdateTime = getEstDateTime(summary.lastUpdateTime);
          const totalValueMoneyPortfolio = moneyValueInUnit(summary.totalValueMoneyPortfolio)? moneyValueInUnit(summary.totalValueMoneyPortfolio).split(' '):"0";
          const totalMoneyIB =  moneyValueInUnit(parseInt(summary.totalMoneyIB))? moneyValueInUnit(parseInt(summary.totalMoneyIB)).split(' '):"0";
          summary.totalValueMoneyPortfolio = totalValueMoneyPortfolio[0];
          summary.totalValueMoneyPortfolioUnit =
            totalValueMoneyPortfolio.length === 2 ? totalValueMoneyPortfolio[1] : '';
          summary.totalMoneyIB = totalMoneyIB[0];
          summary.totalMoneyIBUnit = totalMoneyIB.length === 2 ? totalMoneyIB[1] : '';
          setSummary(summary);
          let latest = response?.data?.data.latest;
          // console.info('latest: %o', latest);
          latest.forEach((s) => {
            s.onlineTime = s.onlineTime?  s.onlineTime.substring(0, 10) : '';
          });
          setLatest(latest);
          const newsletters = response?.data?.data.newsletters;
          newsletters.forEach((n) => {
            n.date  = moment( n.postedTime.replace(/\.\d+/, "").replace(/-/g,"/")).tz('America/New_York').format('YYYY-MM-DD');
          });
          setNewsletter(newsletters);
          const data =
            response?.data?.data?.strategies.length > 0 ? response.data.data.strategies : [];
          data.forEach((item) => {
            const name = item.name.split('ordered by ')[1];
            item.subTitle = name;
          });
          setDashboard(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    getUserOption("g")
      .then((response) => {
        if (response?.data.code === 200) {
          let option = response?.data?.data;
          if (option==='0' || option === null) {
            setGetStartedState({open: true});
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });

    getUserOption("n")
        .then((response) => {
          if (response?.data.code === 200) {
            let option = response?.data?.data;
            // if ( option==='0' || option === null ) {
            if ((option==='0' || option === null) && getStartedState.open === false) {
              setNewfeatureState({open: true});
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });

  }, []);

  const getTableColumn = (type: number) => {
    if (type === 1) {
      return columns1;
    } else if (type === 2) {
      return columns2;
    } else if (type === 3) {
      return columns3;
    } else if (type === 4) {
      return columns4;
    }
  };

  function showNewsletter(item) {
    console.info('popup version %o', item.version);
    getNewsletter(item.version)
      .then((response) => {
        if (response?.data.code === 200) {
          let newsletter = response?.data?.data;
          console.info('newsletter %o', newsletter);
          //reset note
          setNewsletterSubtitle3(null);
          setNewsletterNote(null);
          setNewsletterRating(null);
          newsletter.forEach((news) => {
            if (news.type === 1) {
              let date = moment( news.postedTime.replace(/\.\d+/, "").replace(/-/g,"/")).tz('America/New_York').format('YYYY-MM-DD');
              setNewsletterDate(date);
              setNewsletterSubtitle1(news.subtitle);
              setNewsletterMarketview(news.text);
            } else if (news.type === 2) {
              setNewsletterSubtitle2(news.subtitle);
              setNewsletterRating(news.text);
            } else if (news.type === 3) {
              setNewsletterSubtitle3(news.subtitle);
              setNewsletterNote(news.text);
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    setPopupState({ open: true});
  }

  function showMoneyInvestmentSummary(item) {
    getInvestmentSummary()
        .then((response) => {
          if (response?.data.code === 200) {
            let summary = response?.data?.data;
            console.info('Investment Summary %o', summary.aqs_summary);
            setAqsSummary(summary.aqs_summary);
            setAqmSummary(summary.aqm_summary);
            setSummaryState({ open: true});
          }
        })
        .catch((error) => {
          console.log(error);
        });
  }

  function showGetStarted() {
    setGetStartedState({ open: true});
  }

  function closeGetStarted() {
    setGetStartedState({ open: false});
    setUserOption('g', '1');
  }

  function showNewFeatures() {
    setNewfeatureState({ open: true});
  }

  function closeewFeatures() {
    setNewfeatureState({ open: false});
    setUserOption('n', '1');
  }

  return (
    <div className="mainPagePanel">
      {dashboard && (
        <div>
          <span style={{ fontSize: 12, fontWeight: 400 }}>
            Last Update: {summary.lastUpdateTime}
          </span>
          <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                  <Card style={{paddingRight:'0px'}}>
                    <CardHeader color="success" stats icon>
                      <CardIcon color="success">
                        <Icon>content_copy</Icon>
                      </CardIcon>
                      <p
                        className={classes.cardCategory}
                        style={{ color: '#999', fontSize: 16, fontWeight: 400 }}
                      >
                      </p>
                      <div style={{marginLeft:'100px', whiteSpace: 'nowrap', overflow:'hidden'}}>  {/*content box*/}
                      <div style={{align:'left', marginLeft: '45px', textAlign: 'left',  maxWidth:'460px'}}>{/*content*/}
                      <h3 className={classes.cardTitle}  style={{height: '40px'}}>
                        {/*<small>{stringUtils.dashboard_AUM}: <span>${summary?.totalMoneyIB}</span> {summary?.totalMoneyIBUnit}</small>*/}
                      </h3>
                      <div className={classes.stats}></div>
                      <div style={{ marginTop: '-20px'}}>
                      <h3 className={classes.cardTitle}>
                        <small>
                        <span>
                          <Tooltip title="Click to Start">
                          <a
                              style={{color: '#777', textDecorationLine: 'underline', fontWeight: 400,  }}
                              onClick={() => showGetStarted()}
                          >
                          {stringUtils.getStarted}
                          </a>
                          </Tooltip>
                        </span>
                        </small>
                      </h3>
                      {/*<div style={{ marginBottom: '5px' }}></div>*/}
                      <div style={{ paddingTop: '10px' }}></div>
                      <h3 className={classes.cardTitle}>
                        <small>
                          <span>
                            <Tooltip title="Click to View">
                            <a
                                style={{color: '#777', textDecorationLine: 'underline', fontWeight: 400,  }}
                                onClick={() => showMoneyInvestmentSummary()}
                            >
                            {stringUtils.investmentSummary}
                            </a>
                            </Tooltip>
                          </span>
                        </small>
                      </h3>
                      <div style={{ paddingTop: '10px' }}></div>
                      <h3 className={classes.cardTitle}>
                        <small>{stringUtils.usefulLink}</small>
                      </h3>
                      </div>
                      <div>
                        <span>
                          <a
                              style={{ color: '#00acc1', fontWeight: 400 }}
                              href={Seminar20250328Video}
                              target="_blank"
                              referrerpolicy="origin"
                          >
                            {stringUtils.supportSeminarLatest}
                          </a>
                        </span>
                      </div>
                      <div>
                        <span>
                          <a
                              style={{ color: '#00acc1', fontWeight: 400 }}
                              href={iPapaFQAPdf}
                              target="_blank"
                              rel="noreferrer"
                          >
                            {stringUtils.supportFAQ}
                          </a>
                        </span>
                      </div>
                      <div>
                        <span>
                          <a
                              style={{
                                color: '#00acc1',
                                fontWeight: 400,
                              }}
                              href={iPapaIBSetupPdf}
                              target="_blank"
                              rel="noreferrer"
                          >
                            {stringUtils.supportIBSetup}
                          </a>
                        </span>
                      </div>
                      {newsletter.map(item =>
                          <div key={item.version}>
                            <span>
                              <a
                                  style={{ color: '#00acc1', fontWeight: 400 }}
                                  onClick={() => showNewsletter(item)}
                              >
                              {stringUtils.weeklyNewsletter} {item.date}
                              </a>
                            </span>
                          </div>
                      )}
                      <div style={{ marginBottom: '0px' }}></div>
                      </div>  {/*content box*/}
                      </div> {/*content*/}
                    </CardHeader>
                    <CardFooter stats>
                      {/*<div className={classes.stats}>*/}
                      {/*</div>*/}
                    </CardFooter>
                  </Card>
              </GridItem>
              {/* Latest strategies */}
              <GridItem xs={12} sm={6} md={6}>
                <Card style={{height:'373px'}}>
                  <CardHeader color="info" stats icon>
                    <CardIcon color="warning">
                      <Icon>content_copy</Icon>
                    </CardIcon>
                    <p className={classes.cardCategory}>
                      {/*<h4>&nbsp</h4>*/}
                    </p>
                    <h3 className={classes.cardTitle} style={{whiteSpace: 'nowrap', overflow:'hidden', margin:'auto'}}>
                      <small>{stringUtils.latestStrategies}</small>
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <DataTable
                        title="Activities"
                        data={latest}
                        columns={getTableColumn(4)}
                        // fixedHeader
                        highlightOnHover
                        striped
                        sortIcon={<SortIcon />}
                        noHeader={true}
                    />
                  </CardBody>
                </Card>
              </GridItem>
          </GridContainer>
          {/* dashboard strateges */}
          {dashboard.map((item, index: number) =>
            index % 2 === 0? (
              <GridContainer key={index}>
                <GridItem xs={12} sm={6} md={6}>
                  <Card>
                    <CardHeader color="success" stats icon>
                      <CardIcon color="success">
                        <Icon>content_copy</Icon>
                      </CardIcon>
                      <p className={classes.cardCategory}>
                        <h4>Top {useBenchmark? item.data.length-3:item.data.length} Strategies</h4>
                      </p>
                      <h3 className={classes.cardTitle}>
                        <small>Ordered by</small> {getUppercaseEachWord(item.subTitle)}
                      </h3>
                    </CardHeader>
                    <CardBody>
                      <DataTable
                        title="Activities"
                        data={item.data}
                        columns={getTableColumn(item.type)}
                        // fixedHeader
                        highlightOnHover
                        striped
                        sortIcon={<SortIcon />}
                        noHeader={true}
                      />
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <Card>
                    <CardHeader color="warning" stats icon>
                      <CardIcon color="warning">
                        <Icon>content_copy</Icon>
                      </CardIcon>
                      <p className={classes.cardCategory}>
                        <h4>Top {useBenchmark? dashboard[index + 1].data.length-3: dashboard[index + 1].data.length} Strategies</h4>
                      </p>
                      <h3 className={classes.cardTitle}>
                        <small>Ordered by</small>{' '}
                        {getUppercaseEachWord(dashboard[index + 1].subTitle)}
                      </h3>
                    </CardHeader>
                    <CardBody>
                      <DataTable
                        title="Activities"
                        data={dashboard[index + 1].data}
                        columns={getTableColumn(dashboard[index + 1].type)}
                        // fixedHeader
                        highlightOnHover
                        striped
                        sortIcon={<SortIcon />}
                        noHeader={true}
                      />
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            ) : null
          )}
        </div>
      )}
      {popupState.open === true && (
          <Newsletter
              onClick={() => setPopupState({ open: false })}
              date={newletterDate}
              subtitle1={newsletterSubtitle1}
              marketView={newsletterMarketview}
              subtitle2={newsletterSubtitle2}
              marketRating={newsletterRating}
              subtitle3={newsletterSubtitle3}
              note={newsletterNote}
          />
      )}
      {/*{isLoading === true && (*/}
      {/*  <div className={classes.circularProgressDiv}>*/}
      {/*    <CircularProgress className={classes.circularProgressTop} />*/}
      {/*    <div className={classes.waitingMsg}>Please wait...</div>*/}
      {/*  </div>*/}
      {/*)}*/}
      {summaryState.open === true && (
          <InvestmentSummary
              onClick={() => setSummaryState({ open: false })}
              aqsSummary={aqsSummary}
              aqmSummary={aqmSummary}
          />
      )}
      {getStartedState.open === true && (
          <GetStarted
              onClick={() => closeGetStarted()}
              init={0}
          />
      )}
      {newfeatureState.open === true && getStartedState.open !== true && (
          <NewFeature
              onClick={() => closeewFeatures()}
              init={0}
          />
      )}
    </div>
  );
}
